import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { iif, map, mergeMap, Observable } from 'rxjs';

import { Chambers } from '../../chambers/chamber';
import { CHAMBER_ID } from '../../common/constants/storage';
import { StorageService } from '../../core/services';
import { BillingApiService } from '../../core/services/billing-api.service';
import { selectHasPro, selectUserGetRelevantDataState } from '../../state/billing/selectors';
import { AppState } from '../../state/reducers';

/**
 * Prevents opening any other page when user is not PRO and has valid 'chamberId' in local storage.
 *
 * For more details see Epic: https://diib.atlassian.net/browse/DEV-6240
 */
@Injectable({ providedIn: 'root' })
export class ChamberGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private billingService: BillingApiService,
    private localStorage: StorageService,
  ) {}

  canActivate(): boolean | Observable<boolean | UrlTree> {
    const chamberId = this.localStorage.readData(CHAMBER_ID);
    const siteId = this.localStorage.readData('siteId');

    if (!chamberId || !siteId) {
      this.localStorage.removeDataByKey(CHAMBER_ID);

      return true;
    }

    const isChamberIdValid = !!chamberId && Object.values<string>(Chambers).includes(chamberId);
    const hasPro$ = this.store.select(selectHasPro);
    const isDataReceivedFromServer$ = this.store.select(selectUserGetRelevantDataState);

    if (isChamberIdValid) {
      const paymentDataLoaded$ = hasPro$.pipe(map((hasPro) => handlePaymentAccess(hasPro)));

      const paymentDataAbsent$ = this.billingService
        .getHasProAccess()
        .pipe(map((hasPro) => handlePaymentAccess(hasPro)));

      const handlePaymentAccess = (hasPro) => {
        if (hasPro) {
          this.localStorage.removeDataByKey(CHAMBER_ID);

          return true;
        } else {
          return this.router.parseUrl(`/chambers/${chamberId}`);
        }
      };

      return isDataReceivedFromServer$.pipe(
        mergeMap((isReceived) => iif(() => isReceived, paymentDataLoaded$, paymentDataAbsent$)),
      );
    } else {
      const error = `[ChamberGuard] Invalid "chamberId" found: ${chamberId}`;

      console.error(error);
      Sentry.captureException(error);
      /* Let user through to prevent blocking him*/
      this.localStorage.removeDataByKey(CHAMBER_ID);

      return true;
    }
  }
}
